import { VALIDATION_MESSAGES, validateRequiredField } from './utils';
import { isCaymanIslands, isUnitedStates } from '../../../models';
import { useEffect, useState } from 'react';

import { ForeignRegistration } from '../LegalEntityDocumentPreparation/model';
import { ValidationErrors } from '../context/model';
import { getRegisteredAgents } from '../LegalEntityDocumentPreparation/utils';
import { isEmpty } from '../../../Utilities/Validations';
import { updateValidation } from '../context/actions/form-actions';
import { useLegalEntityDocumentPreparation } from '../context/Provider';

export default function useForeignRegistrationDetailsValidation() {
    const {
        state: {
            data: {
                values: { foreignRegistrations },
            },
        },
        dispatch,
    } = useLegalEntityDocumentPreparation();

    const [validations, setValidations] = useState<ValidationErrors<ForeignRegistration>[]>([]);

    useEffect(() => {
        if (isEmpty(foreignRegistrations)) {
            setValidations([]);
            return;
        }

        setValidations(
            foreignRegistrations.map(({ country, registeredAgent, state, isDeleted }) =>
                isDeleted
                    ? {}
                    : {
                          country: validateRequiredField(country),
                          registeredAgent: validateRequiredField(registeredAgent, {
                              isRequired: getRegisteredAgents(country),
                          }),
                          state: validateRequiredField(state, {
                              isRequired: isUnitedStates(country) || isCaymanIslands(country),
                          }),
                      }
            )
        );
    }, [foreignRegistrations, dispatch]);

    useEffect(() => {
        if (isEmpty(validations)) {
            updateValidation(dispatch, { foreignRegistrations: undefined });
            return;
        }

        const hasValidationError = validations.some((validation) => Object.values(validation).some((value) => value));
        const hasOtherThanRequiredValidationError =
            hasValidationError &&
            validations.some((validation) =>
                Object.values(validation).some((value) => value && value !== VALIDATION_MESSAGES.REQUIRED_FIELD)
            );

        updateValidation(dispatch, {
            foreignRegistrations: hasValidationError
                ? hasOtherThanRequiredValidationError
                    ? VALIDATION_MESSAGES.COMBINED_ERROR
                    : VALIDATION_MESSAGES.REQUIRED_FIELD
                : undefined,
        });
    }, [validations, dispatch]);

    return { validations };
}
