export const constants = {
    // constants
    ENTITY_MASTER_SYSTEM_BRN: 'bx:bxt:entity-master',
    // env vars
    IDENTITYSERVER_BASEURL: 'https://bxid-ext.blackstone.com/identity/',
    IDENTITYSERVER_CLIENTID: 'com.bx.bxt.bx-legal-entities-ui-external',
    SWAGGER_REDIRECT_URL: 'https://entityrequest.blackstone.com/',
    SILENT_REDIRECT_URL: 'https://entityrequest.blackstone.com/silent-refresh.html',
    USERINFO_API: 'https://entityrequest.blackstone.com/users/my/info',
    IDENTITYSERVER_SCOPE: 'baamApi openid profile',
    RESPONSE_TYPE: 'code',
    AUTO_SILENT_RENEW: 'true',
    AUTOLOGIN: 'true',
    AUTOMATICSILENTRENEW: 'true',
    ENVIRONMENT: 'prod',
    VERSION: '2758',

    ENTITY_MASTER_API: 'https://entityrequest.blackstone.com/request-api'.replace(/\/$/, '') + '/',
    USERS_API: 'https://entityrequest.blackstone.com/users',
    POLICY_SERVER_API: 'https://entityrequest.blackstone.com/policy-server/',
    MDM_WORKFLOWS_URL: 'https://bxaccess.com/Workflows/Workflow/Index/',
    TRIAL_BALANCES_DASHBOARD_URL: 'https://app.sigmacomputing.com/blackstone/workbook/Trial-Balances-for-Legal-Entity-Dissolution-47LXdThfC4QAQuvgMId5A1',
    INTERCOMPANY_BALANCES_DASHBOARD_URL: 'https://app.sigmacomputing.com/blackstone/workbook/Intercompany-Balances-for-Legal-Entity-Dissolution-51VQWTPr7vKxphSF5EEoI7',
    WORKFLOWS_TOOL_URL: 'https://www.bxaccess.com/workflows/Dashboard/BankAccount',

    DEFAULT_NDAYS_DASHBOARD: '30',
    FILE_UPLOAD_MAX_LIMIT_IN_MB: '21',
    PARENT_FUND_BUSINESS_UNIT_ID_FILTER: '100000,100001,100002,100003,2430504,4599023,7869009,13933972,14266842,15715285,18741817,29710353',
};
