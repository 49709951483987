import { VALIDATION_MESSAGES, isEqual, validateRequiredField } from './utils';
import { useCallback, useEffect, useState } from 'react';

import { TaxCode } from 'models';
import { TaxCodeType } from '../../../models/LegalEntityRequest/DefaultValues';
import { ValidationErrors } from '../context/model';
import { isEmpty } from '../../../Utilities/Validations';
import { updateValidation } from '../context/actions/form-actions';
import { useLegalEntityInformation } from '../context/Provider';

export default function useTaxCodesValidation() {
    const {
        state: {
            data: {
                values: { taxCodes },
            },
            isEditable,
        },
        dispatch,
    } = useLegalEntityInformation();

    const [validations, setValidations] = useState<ValidationErrors<TaxCode>[]>([]);

    const hasTaxTypeDuplication = useCallback(
        (checkedTaxCode: TaxCode) => {
            if (!taxCodes) {
                return;
            }

            const uniqueValidation =
                taxCodes.filter(
                    ({ taxCodeTypeId, isDeleted }) =>
                        checkedTaxCode.taxCodeTypeId !== TaxCodeType.GIIN &&
                        isEqual(checkedTaxCode.taxCodeTypeId, taxCodeTypeId) &&
                        !isDeleted
                ).length > 1;

            const requiredValidation = isEmpty(checkedTaxCode.taxCodeTypeId);
            if (requiredValidation) return VALIDATION_MESSAGES.REQUIRED_FIELD;
            if (uniqueValidation) return VALIDATION_MESSAGES.UNIQUE_TAX_TYPE;
            return;
        },
        [taxCodes]
    );

    const hasTaxCodeDuplication = useCallback(
        (checkedTaxCode: TaxCode) => {
            if (!taxCodes) {
                return;
            }

            const validation =
                taxCodes.filter(({ taxCode, isDeleted }) => isEqual(checkedTaxCode.taxCode, taxCode) && !isDeleted)
                    .length > 1;
            const requiredValidation = isEmpty(checkedTaxCode.taxCode);
            if (requiredValidation) return VALIDATION_MESSAGES.REQUIRED_FIELD;
            if (validation) return VALIDATION_MESSAGES.UNIQUE_TAX_CODE;
            return;
        },
        [taxCodes]
    );

    useEffect(() => {
        setValidations(
            isEmpty(taxCodes)
                ? []
                : taxCodes.map((taxCode) =>
                      taxCode.isDeleted
                          ? {}
                          : {
                                taxCodeTypeId: hasTaxTypeDuplication(taxCode),
                                taxCode: hasTaxCodeDuplication(taxCode),
                                taxCountry: validateRequiredField(taxCode.taxCountry),
                            }
                  )
        );
    }, [taxCodes, dispatch, hasTaxTypeDuplication, hasTaxCodeDuplication]);

    useEffect(() => {
        if (!isEditable || isEmpty(validations)) {
            updateValidation(dispatch, { taxCodes: undefined });
            return;
        }

        const hasValidationError = validations.some((validation) => Object.values(validation).some((value) => value));
        const hasOtherThanRequiredValidationError =
            hasValidationError &&
            validations.some((validation) =>
                Object.values(validation).some((value) => value && value !== VALIDATION_MESSAGES.REQUIRED_FIELD)
            );

        updateValidation(dispatch, {
            taxCodes: hasValidationError
                ? hasOtherThanRequiredValidationError
                    ? VALIDATION_MESSAGES.COMBINED_ERROR
                    : VALIDATION_MESSAGES.REQUIRED_FIELD
                : undefined,
        });
    }, [validations, dispatch, isEditable]);

    return { validations };
}
