import {
    AttributeBoolean,
    AttributeDate,
    AttributeString,
    AttributeType,
    EntityHierarchy,
    EntityHierarchyIdentifier,
    EntityHierarchyTypes,
    LegalEntity,
    LegalEntityDTO,
} from '../../../../models';
import { LegalEntityInformationData, RegistrationData } from '../../LegalEntityInformation/model';
import {
    createOrUpdateBooleanValue,
    createOrUpdateDateValue,
    createOrUpdateStringValue,
    getAddresses,
    getBooleanAttributes,
    getDateAttributes,
    getDomesticRegistrationDataForSection,
    getStringAttributes,
    getTaxCodes,
    getTaxRegistrations,
    setIfDefined,
} from './helpers';
import { isEmpty, isNil } from 'lodash';

import { RecursivePartial } from '../../../../Utilities/ReflectionUtil';
import { isNullOrUndefined } from 'util';

export const mapToSectionData = (entityDto: LegalEntityDTO): LegalEntityInformationData => {
    const { entity, entityHierarchies, madeInNewSystems } = entityDto;
    const {
        legalEntity: {
            businessUnitOId,
            subBusinessUnitOId,
            administeredBy,
            legalTypeID,
            taxEntityTypeId,
            newStructureTypeId,
            businessDriver,
            isDisregardedEntity,
            legalStatusId,
        },
        primaryName,
        shortName,
        taxRegistrations,
        latestDomesticTaxRegistrationId,
        objectAttributeBooleans,
        objectAttributeStrings,
        objectAttributeDates,
        addresses,
        taxCodes,
    } = entity;

    const projectEntity = entityHierarchies?.filter(EntityHierarchyTypes.project.isOfType).slice(-1)[0];
    const externalAdminEntity = entityHierarchies?.filter(EntityHierarchyTypes.externalAdmin.isOfType).slice(-1)[0];
    const administratorHKAPACEntity = entityHierarchies
        ?.filter(EntityHierarchyTypes.administratorHKAPAC.isOfType)
        .slice(-1)[0];
    const portfolioEntity = entityHierarchies?.filter(EntityHierarchyTypes.portfolio.isOfType).slice(-1)[0];
    const lawFirmEntity = entityHierarchies?.filter(EntityHierarchyTypes.lawFirm.isOfType).slice(-1)[0];
    const taxAuditorEntity = entityHierarchies?.filter(EntityHierarchyTypes.taxAuditor.isOfType).slice(-1)[0];
    const associatedDeal = entityHierarchies?.filter(EntityHierarchyTypes.associatedDeal.isOfType).slice(-1)[0];

    const isOldStructureType = isNullOrUndefined(newStructureTypeId) && !isNullOrUndefined(taxEntityTypeId);
    const structureType = isOldStructureType ? taxEntityTypeId : newStructureTypeId;
    const booleanAttributes = getBooleanAttributes(objectAttributeBooleans);
    const stringAttributes = getStringAttributes(objectAttributeStrings);
    const dateAttributes = getDateAttributes(objectAttributeDates);

    const domesticRegistrationData =
        taxRegistrations === null || isEmpty(taxRegistrations)
            ? undefined
            : getDomesticRegistrationDataForSection(taxRegistrations, latestDomesticTaxRegistrationId);

    return {
        entityOid: entity.entityOId,
        legalName: primaryName,
        shortName: shortName,
        businessDriverId: businessDriver,
        businessUnitOId,
        subBusinessUnitOId,
        administeredBy,
        legalTypeId: legalTypeID,
        legalStatusId,
        structureType,
        isOldStructureType,
        projectEntityOId: projectEntity?.parentEntityOId, //search in elastic search
        externalAdminOId: externalAdminEntity?.childEntityOId, //maps ref data
        administratorHKAPACOId: administratorHKAPACEntity?.childEntityOId, //maps ref data
        lawFirmEntityOId: lawFirmEntity?.childEntityOId, //maps to ref data
        portfolioId: portfolioEntity?.childEntityOId, //maps ref data
        externalAdminContact: externalAdminEntity?.serviceProviderDetails?.find(
            EntityHierarchyTypes.externalAdmin.isOfDetailType
        )?.serviceProviderContactEmailId, //map to ref data
        taxAuditorOId: taxAuditorEntity?.childEntityOId, //maps to ref data
        associatedDeal: associatedDeal?.parentEntityOId, //maps to ref data
        isBlueprintEntity: booleanAttributes[AttributeType.isBlueprintEntity],
        isOrderChop: booleanAttributes[AttributeType.isOrderChop],
        isOrderSeal: booleanAttributes[AttributeType.isOrderSeal],
        hedgehogID: stringAttributes[AttributeType.hedgehogID],
        remarks: stringAttributes[AttributeType.remarks],
        financialYearEndDate: dateAttributes[AttributeType.financialYearEndDate],
        isDisregardedEntity,
        RegistrationData: domesticRegistrationData ? domesticRegistrationData : [],
        addresses,
        taxCodes,
        madeInNewSystems,
    };
};

export const mapFromSectionData = (
    original: LegalEntityDTO | undefined,
    section: Partial<LegalEntityInformationData> & RegistrationData
) => {
    const toReturn = {} as RecursivePartial<LegalEntityDTO>;
    const legalEntity = {} as Partial<LegalEntity>;
    const entityHierarchiesToDelete: LegalEntityDTO['removedEntityHierarchies'] = [];
    const entityHierarchies: RecursivePartial<EntityHierarchy>[] = [];
    const booleanAttributes: Partial<AttributeBoolean>[] = [];
    const stringAttributes: Partial<AttributeString>[] = [];
    const dateAttributes: Partial<AttributeDate>[] = [];
    const associatedDealsToDelete: LegalEntityDTO['removedAssociatedDeals'] = [];

    const { addresses, removedAddresses } = getAddresses(section.addresses);
    const { taxCodes, removedTaxCodes } = getTaxCodes(section.taxCodes);
    const { taxRegistrations, removedTaxRegistrations } = getTaxRegistrations(section.RegistrationData);

    const createOrUpdateBoolean = (attributeType: AttributeType, value: boolean | undefined) =>
        createOrUpdateBooleanValue({ original, booleanAttributes, attributeType, value, checkSource: true });

    const createOrUpdateString = (attributeType: AttributeType, value: string | undefined) =>
        createOrUpdateStringValue({ original, stringAttributes, attributeType, value });

    const createOrUpdateDate = (attributeType: AttributeType, value: Date | undefined) =>
        createOrUpdateDateValue({ original, dateAttributes, attributeType, value });

    setIfDefined(section, 'isBlueprintEntity', (v) => createOrUpdateBoolean(AttributeType.isBlueprintEntity, v));
    setIfDefined(section, 'isOrderChop', (v) => createOrUpdateBoolean(AttributeType.isOrderChop, v));
    setIfDefined(section, 'isOrderSeal', (v) => createOrUpdateBoolean(AttributeType.isOrderSeal, v));
    setIfDefined(section, 'isDisregardedEntity', (v) => (legalEntity.isDisregardedEntity = v));
    setIfDefined(section, 'legalName', (ln) => (toReturn.entity = { primaryName: ln?.trim() }));
    setIfDefined(section, 'shortName', (sn) => (toReturn.entity = { ...toReturn.entity, shortName: sn?.trim() }));
    setIfDefined(section, 'businessDriverId', (v) => (legalEntity.businessDriver = v));
    setIfDefined(section, 'businessUnitOId', (v) => (legalEntity.businessUnitOId = v));
    setIfDefined(section, 'remarks', (v) => createOrUpdateString(AttributeType.remarks, v));
    setIfDefined(section, 'financialYearEndDate', (v) => createOrUpdateDate(AttributeType.financialYearEndDate, v));
    setIfDefined(section, 'hedgehogID', (v) => createOrUpdateString(AttributeType.hedgehogID, v));
    setIfDefined(section, 'subBusinessUnitOId', (v) => (legalEntity.subBusinessUnitOId = v));
    setIfDefined(section, 'administeredBy', (v) => (legalEntity.administeredBy = v));
    setIfDefined(section, 'legalTypeId', (v) => (legalEntity.legalTypeID = v));
    setIfDefined(section, 'structureType', (v) => (legalEntity.newStructureTypeId = v));
    setIfDefined(section, 'legalStatusId', (v) => (legalEntity.legalStatusId = v));

    const setEntityOId = (type: EntityHierarchyIdentifier, entityOId: number | undefined) => {
        const existingHierarchy = original?.entityHierarchies?.find(type.isOfType);
        if (isNullOrUndefined(entityOId)) {
            if (existingHierarchy?.entityHierarchyOId === undefined) return;
            const { entityHierarchyOId } = existingHierarchy;
            entityHierarchiesToDelete.push({ entityHierarchyOId });
            return;
        }

        let entityHierarchyUpdate: RecursivePartial<EntityHierarchy> = { [type.entityOIdField]: entityOId };
        if (!isNullOrUndefined(existingHierarchy)) {
            entityHierarchyUpdate.entityHierarchyTypeId = existingHierarchy.entityHierarchyTypeId;
            entityHierarchyUpdate.serviceProviderDetails = [
                {
                    serviceProviderTypeId: existingHierarchy.serviceProviderDetails[0]?.serviceProviderTypeId,
                },
            ];
        } else {
            entityHierarchyUpdate = type.generateHierarchy(entityHierarchyUpdate);
        }
        if (existingHierarchy && existingHierarchy.entityHierarchyOId) {
            const { entityHierarchyOId } = existingHierarchy;
            entityHierarchiesToDelete.push({ entityHierarchyOId });
        }
        entityHierarchies.push(entityHierarchyUpdate);
    };

    setIfDefined(section, 'projectEntityOId', setEntityOId.bind(null, EntityHierarchyTypes.project));
    setIfDefined(section, 'externalAdminOId', setEntityOId.bind(null, EntityHierarchyTypes.externalAdmin));
    setIfDefined(section, 'portfolioId', setEntityOId.bind(null, EntityHierarchyTypes.portfolio));
    setIfDefined(section, 'lawFirmEntityOId', setEntityOId.bind(null, EntityHierarchyTypes.lawFirm));
    setIfDefined(section, 'administratorHKAPACOId', setEntityOId.bind(null, EntityHierarchyTypes.administratorHKAPAC));
    setIfDefined(section, 'taxAuditorOId', setEntityOId.bind(null, EntityHierarchyTypes.taxAuditor));

    const setAssociatedDeal = (type: EntityHierarchyIdentifier, entityOId: number | undefined) => {
        const existingHierarchy = original?.entityHierarchies?.find(type.isOfType);
        if (isNil(entityOId)) {
            if (existingHierarchy?.entityHierarchyOId === undefined) {
                return;
            }

            const { entityHierarchyOId } = existingHierarchy;
            associatedDealsToDelete.push(entityHierarchyOId);
            return;
        }

        let entityHierarchyUpdate: RecursivePartial<EntityHierarchy> = { [type.entityOIdField]: entityOId };
        if (!isNil(existingHierarchy)) {
            entityHierarchyUpdate.entityHierarchyTypeId = existingHierarchy.entityHierarchyTypeId;
        } else {
            entityHierarchyUpdate = type.generateHierarchy(entityHierarchyUpdate);
        }
        if (existingHierarchy && existingHierarchy.entityHierarchyOId) {
            const { entityHierarchyOId } = existingHierarchy;
            associatedDealsToDelete.push(entityHierarchyOId);
        }
        entityHierarchies.push(entityHierarchyUpdate);
    };
    setIfDefined(section, 'associatedDeal', setAssociatedDeal.bind(null, EntityHierarchyTypes.associatedDeal));

    const setContact = (hierarchyType: EntityHierarchyIdentifier, v: number | undefined) => {
        const existingHierarchy = original?.entityHierarchies?.find(hierarchyType.isOfType);
        const existingDetail = existingHierarchy?.serviceProviderDetails?.find(hierarchyType.isOfDetailType);
        let updateHierarchy = entityHierarchies.find(
            (e) =>
                (existingHierarchy !== undefined && e.entityHierarchyOId === existingHierarchy.entityHierarchyOId) ||
                (e.entityHierarchyTypeId !== undefined &&
                    hierarchyType.isOfType(e as { entityHierarchyTypeId: number; serviceProviderDetails: [] }))
        );
        const hierarchyPendingDelete = entityHierarchiesToDelete.find(
            (h) => h.entityHierarchyOId === existingHierarchy?.entityHierarchyOId
        );
        if (hierarchyPendingDelete && (hierarchyPendingDelete.serviceProviderDetailIds?.length ?? 0) === 0) return;
        if (isNullOrUndefined(v) && !existingDetail) return;
        if (!updateHierarchy) {
            if (!existingHierarchy) {
                return console.warn(`Could not find a hierarchy for contact ${v}. Ignoring.`);
            }
            updateHierarchy = { entityHierarchyOId: existingHierarchy.entityHierarchyOId };
            entityHierarchies.push(updateHierarchy);
        }

        if (updateHierarchy) {
            let updateDetail = updateHierarchy.serviceProviderDetails?.find(hierarchyType.isOfDetailType);
            if (!updateDetail) {
                updateHierarchy.serviceProviderDetails = updateHierarchy.serviceProviderDetails || [];
                if (existingDetail) {
                    updateDetail = {
                        serviceProviderContactEmailId: v,
                        serviceProviderDetailId: existingDetail.serviceProviderDetailId,
                    };
                } else {
                    updateDetail = {
                        serviceProviderTypeId: hierarchyType.serviceProviderType,
                        serviceProviderContactEmailId: v,
                    };
                }
                updateHierarchy.serviceProviderDetails.push(updateDetail);
            } else {
                updateDetail.serviceProviderContactEmailId = v;
            }
        }
    };

    setIfDefined(section, 'externalAdminContact', setContact.bind(null, EntityHierarchyTypes.externalAdmin));

    toReturn.entity = toReturn.entity ?? {};

    if (Object.keys(legalEntity).length > 0) {
        toReturn.entity.legalEntity = legalEntity;
    }

    if (addresses.length > 0 && toReturn.entity) toReturn.entity.addresses = addresses;
    if (taxCodes.length > 0 && toReturn.entity) toReturn.entity.taxCodes = taxCodes;
    if (taxRegistrations.length > 0 && toReturn.entity) toReturn.entity.taxRegistrations = taxRegistrations;
    if (booleanAttributes.length > 0 && toReturn.entity) toReturn.entity.objectAttributeBooleans = booleanAttributes;
    if (stringAttributes.length > 0 && toReturn.entity) toReturn.entity.objectAttributeStrings = stringAttributes;
    if (dateAttributes.length > 0 && toReturn.entity) toReturn.entity.objectAttributeDates = dateAttributes;
    if (entityHierarchiesToDelete.length > 0) toReturn.removedEntityHierarchies = entityHierarchiesToDelete;
    if (entityHierarchies.length > 0) toReturn.entityHierarchies = entityHierarchies;
    if (removedAddresses.length > 0) toReturn.removedAddresses = removedAddresses;
    if (removedTaxCodes.length > 0) toReturn.removedTaxCodes = removedTaxCodes;
    if (removedTaxRegistrations.length > 0) toReturn.removedTaxRegistrations = removedTaxRegistrations;
    if (associatedDealsToDelete.length > 0) toReturn.removedAssociatedDeals = associatedDealsToDelete;

    return toReturn;
};
